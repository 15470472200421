import React from "react"
import Preview from "./preview";

import Googleanalytics from "./googleanalytics";

export default function () {
  return (
    <React.Fragment>
      <Preview />
      <Googleanalytics />
    </React.Fragment>
  )
}
