import React, { Component } from "react";
import Helmet from "react-helmet";

const gaString = 'UA-130950137-1';

export default class Googleanalytics extends Component {

  componentDidMount(props) {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments)}
    gtag('js', new Date());

    gtag('config', gaString);
  }

  render(props) {
    let gaStringFull = 'https://www.googletagmanager.com/gtag/js?id=' + gaString;
    return (
      <div className="analytics" id="googleAnalytics">
        <Helmet>
          <script async src={gaStringFull}></script>
        </Helmet>
      </div>
    )
  }
}
